import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import Moment from "react-moment"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../constants"
import Layout from "../components/Layout"
import Item from "../components/cart/Item"
import Totals from "../components/order/Totals"
import { useLocation } from "@reach/router"
import { BrinkContext } from "../components/context/BrinkContext"
import { placedOrder } from "../components/context/utils/klaviyoEvents"

const Container = styled.div`
  max-width: 70rem;
  margin: 14.2rem auto 0;
  padding: 0;
`

const PageTitle = styled.h1`
  padding: 4rem 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${MEDIA_MIN_MEDIUM} {
    padding: 6rem 3rem 0;
  }

  i {
    color: ${(p) => p.theme.colors.success};
    font-size: 3rem;
    margin: 0.4rem 1rem 0 0;
    display: block;
  }
`

const PageSubTitle = styled.p`
  text-align: center;
  padding: 0;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-top: 0;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.6rem;
  }
`

const GeneralInfo = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 70rem;
  margin: 4rem auto 6rem;
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  padding: 2rem 3rem 1.8rem;
`

const Box = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-top: 0.1rem solid ${(p) => p.theme.colors.secondary};

  &:first-child {
    border: none;
  }
`

const Title = styled.p`
  text-transform: uppercase;
  font-size: 1.3rem;
  display: inline-block;

  ${MEDIA_MIN_MEDIUM} {
    display: block;
  }
`

const Value = styled.p`
  font-size: 1.3rem;
  text-transform: uppercase;
  display: inline-block;
`

const Summary = styled.div`
  position: relative;
  border-top: 0.1rem solid ${(p) => p.theme.colors.secondary};
  padding: 2rem 0 2rem;
  margin: 0 3rem 4rem;

  ${MEDIA_MIN_MEDIUM} {
    border: 0.1rem solid ${(p) => p.theme.colors.secondary};
    padding: 2rem 3rem 2rem;
    margin: 0 auto 6rem;
  }

  h3 {
    font-family: "Josefin Sans", "sans-serif";
    position: absolute;
    background: ${(p) => p.theme.colors.white};
    top: -2.1rem;
    padding: 0 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1.4rem;
    white-space: nowrap;

    ${MEDIA_MIN_MEDIUM} {
      left: auto;
      transform: translate(0, 0);
    }
  }
`

const Items = styled.div`
  width: 100%;
`

const OrderTotals = styled(Totals)`
  > div {
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: 0.1rem solid ${(p) => p.theme.colors.primaryLight};

      ${MEDIA_MIN_MEDIUM} {
        border-bottom: none;
      }
    }
  }
`

export default function SuccessPage({ pageContext }) {
  const { t } = useTranslation("translations")
  const location = useLocation()
  const { currentStore, discountCode, closeCart, getOrderConfirmation } = useContext(BrinkContext)
  const [order, setOrder] = useState(null)
  const useMountEffect = (fun) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(fun, [])
  }

  const query = new URLSearchParams(location.search)
  const orderId = query.get("orderId")
  const signature = query.get("signature")
  const resultCode = query.get("resultCode")

  const {
    order: stateOrder,
    billingAddress,
    cart,
    signature: stateSignature,
    orderId: stateOrderId,
    resultCode: stateResultCode,
    paymentMethod: statePaymentMethod
  } = location.state ?? {}

  useMountEffect(() => {
    const run = async () => {
      let order
      const fetchSignature = signature ?? stateSignature
      const fetchOrderId = orderId ?? stateOrderId
      if (fetchOrderId && fetchSignature) {
        order = await getOrderConfirmation(fetchOrderId, fetchSignature).catch((error) => {
          console.error("get order full error:", error)
          return navigate("/")
        })
      } else {
        order = {
          ...stateOrder,
          person: billingAddress,
          cart: cart
        }
      }

      closeCart(true)
      setOrder(order)
      try {
        placedOrder(order.person, currentStore, order, discountCode, order.cart.cartItems)
      } catch (error) {
        console.log(error)
      }
    }

    run()
  })

  if (!order) return null

  const paymentMethod = order.paymentMethod ?? statePaymentMethod
  return (
    <Layout meta={{ title: "Success" }} invertedHeader hideCountrySelector pageContext={pageContext}>
      <Container>
        <PageTitle>
          <i className="fal fa-check-circle"></i>
          {t("Thank you")}
        </PageTitle>
        <PageSubTitle>
          {resultCode?.toLowerCase() === "authorised" || stateResultCode?.toLowerCase() === "authorised"
            ? t("Your order has been authorised.")
            : t("Your order has been received, wait for the confirmation email for the final result.")}
        </PageSubTitle>
        <GeneralInfo>
          <Box>
            <Title>{t("Order number")}:</Title>
            <Value>{order.reference}</Value>
          </Box>
          <Box>
            <Title>{t("Date")}:</Title>
            <Value>
              <Moment format="MMM DD, YYYY">{Date.now()}</Moment>
            </Value>
          </Box>
          {paymentMethod && (
            <Box>
              <Title>{t("Payment method")}:</Title>
              <Value>{t(`Payment method ${paymentMethod}`)}</Value>
            </Box>
          )}
        </GeneralInfo>
        <Summary>
          <h3>{t("Order summary")}</h3>
          <Items>
            {order.cart.cartItems.map((item) => {
              const orderLine = order.orderLines.find((orderLine) => orderLine.productId === item.id)
              return (
                orderLine && (
                  <Item item={{ ...item, quantity: orderLine.quantity }} key={item.id} showControls={false} />
                )
              )
            })}
          </Items>
          <OrderTotals order={order} />
        </Summary>
      </Container>
    </Layout>
  )
}
